import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
  TextField,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Snackbar,
  Alert,
  Avatar,
  Paper,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery,
  Tooltip,
  Stack,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemSecondaryAction, InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import {
  Description as DescriptionIcon,
  ExpandMore as ExpandMoreIcon,
  AttachMoney as MoneyIcon,
  CalendarToday as CalendarIcon,
  Note as NoteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  Image as ImageIcon,
  AttachFile as FileIcon,
  Delete as DeleteIcon,
  PersonAdd as PersonAddIcon,
  Person as PersonIcon,
  Remove as RemoveIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';

// Import all required components
import AttendanceCalendar from '../components/AttendanceCalendar';
import AttendanceManagement from '../components/AttendanceManagement';
import StudentProgress from '../components/StudentProgress';
import AddEditStudentModal from '../components/AddEditStudentModal';


// Import all required API functions
import {
  getUser,
  getStudentCourses,
  getStudyMaterialsForStudent,
  listStudentFeedbacks1,
  getCourseHoldHistory1,
  updateUserMe,
  getCurrentUser,
  getStudentAttendances,
  createCourseHold,
  getStudentNotes,
  addStudentNote,
  updateStudentFields,
  getStudentDetails,
  getTrainerAssignmentHistory,
  updateStudentNote,
  deleteStudentNote,
  getStudentCourseFlows,
  listSyllabusItems,
  customizeStudentFlow,
  getStudentCourseFlow,
  listStudentCourseFlows,
  getStudentProgressReport, getImageUrl
} from '../api';

// Styled Components
const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.08)',
  }
}));

const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '12px',
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
  }
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
  '&:disabled': {
    background: 'linear-gradient(45deg, #808080, #A0A0A0)',
    color: 'rgba(255, 255, 255, 0.5)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    color: theme.palette.common.white,
    padding: '12px 16px',
    minHeight: 48,
    textTransform: 'none',
    fontSize: '0.9rem',
    fontWeight: 500,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  '& .Mui-selected': {
    color: theme.palette.primary.main,
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiTabs-scrollButtons': {
    color: 'white',
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  color: 'white',
  borderRadius: '8px !important',
  marginBottom: theme.spacing(2),
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
  }
}));

// Main Component
const StudentDetailsBig = () => {
  // URL Parameters
  const { studentId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Core States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [tabValue, setTabValue] = useState(0);
  const [openAssignModal, setOpenAssignModal] = useState(false);


  // Data States
  const [studentData, setStudentData] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [holdHistory, setHoldHistory] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [notes, setNotes] = useState([]);
  const [assignmentHistory, setAssignmentHistory] = useState([]);
  const [courseFlowData, setCourseFlowData] = useState([]);
  const [availableItems, setAvailableItems] = useState([]);
  const [currentFlowItems, setCurrentFlowItems] = useState([]);

  // UI States
  const [editMode, setEditMode] = useState(false);
  const [editingFees, setEditingFees] = useState(false);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [expandedItem, setExpandedItem] = useState(null);

  // Form States
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newNote, setNewNote] = useState({
    title: '',
    content: '',
    file_attachment: null,
    image_attachment: null
  });
  const [updatedFees, setUpdatedFees] = useState({
    admission_date: null,
    fee_paid: '',
    fee_due: '',
    fee_due_date: null,
    fee_method: ''
  });
  const [newHold, setNewHold] = useState({
    start_date: null,
    end_date: null,
    reason: ''
  });

  // Data Fetching Functions
  const fetchUserRole = useCallback(async () => {
    try {
      const response = await getCurrentUser();
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user role:', error);
      setUserRole('');
    }
  }, []);

  const fetchStudentData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      // Fetch all student data in parallel
      const [
        studentResponse,
        coursesResponse,
        materialsResponse,
        feedbackResponse,
        holdResponse,
        attendanceResponse,
        notesResponse,
        courseFlowResponse
      ] = await Promise.all([
        getStudentDetails(studentId),
        getStudentCourses(studentId),
        getStudyMaterialsForStudent(studentId),
        listStudentFeedbacks1(studentId),
        getCourseHoldHistory1(studentId),
        getStudentAttendances({ student: studentId }),
        getStudentNotes(studentId),
        getStudentCourseFlows(studentId)
      ]);

      setStudentData(studentResponse.data);
      setCourseData(coursesResponse.data);
      setStudyMaterials(materialsResponse.data);
      setFeedbackHistory(feedbackResponse.data);
      setHoldHistory(holdResponse.data);
      setAttendanceData(attendanceResponse.data);
      setNotes(notesResponse.data);
      setCourseFlowData(courseFlowResponse.data);

      if (courseFlowResponse.data.length === 1) {
        setSelectedFlow(courseFlowResponse.data[0].id);
      }

      // Initialize fee form data
      setUpdatedFees({
        admission_date: studentResponse.data.admission_date,
        fee_paid: studentResponse.data.fee_paid || '',
        fee_due: studentResponse.data.fee_due || '',
        fee_due_date: studentResponse.data.fee_due_date,
        fee_method: studentResponse.data.fee_method || ''
      });

      // Fetch assignment history for each course
      if (coursesResponse.data.length > 0) {
        const historyPromises = coursesResponse.data.map(course =>
          getTrainerAssignmentHistory(course.id)
            .then(response => response.data)
            .catch(err => {
              console.error(`Error fetching history for course ${course.id}:`, err);
              return [];
            })
        );

        const allHistories = await Promise.all(historyPromises);
        setAssignmentHistory(allHistories.flat());
      }

    } catch (err) {
      console.error('Error fetching student data:', err);
      setError('Failed to fetch student data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [studentId]);

  // Effect Hooks
  useEffect(() => {
    fetchUserRole();
  }, [fetchUserRole]);

  useEffect(() => {
    if (studentId) {
      fetchStudentData();
    }
  }, [fetchStudentData, studentId]);

  useEffect(() => {
    const fetchAvailableItems = async () => {
      try {
        const response = await listSyllabusItems();
        setAvailableItems(response.data);
      } catch (err) {
        console.error('Error fetching syllabus items:', err);
        setError('Failed to fetch available items');
      }
    };

    fetchAvailableItems();
  }, []);

  useEffect(() => {
    const fetchCurrentFlow = async () => {
      if (!selectedFlow) return;
      
      setLoading(true);
      try {
        const response = await getStudentCourseFlow(selectedFlow);
        const items = response.data.flow_items.map(item => item.syllabus_item);
        setCurrentFlowItems(items);
      } catch (err) {
        console.error('Error fetching current flow:', err);
        setError('Failed to fetch current flow items');
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentFlow();
  }, [selectedFlow]);

  // Handler Functions
  const handleEditProfile = () => {
    if (!studentData) return;
    
    setEditMode(true);
    setUpdatedProfile({
      first_name: studentData.first_name || '',
      last_name: studentData.last_name || '',
      email: studentData.email || '',
      phone: studentData.phone || '',
      city: studentData.city || '',
      about_me: studentData.about_me || '',
      date_of_birth: studentData.date_of_birth || null,
    });
  };

  const handleAssignCourse = () => {
    setOpenAssignModal(true);
  };

  const handleSaveAssignment = async (updatedData) => {
    try {
      // Refresh the course data after assignment
      const coursesResponse = await getStudentCourses(studentId);
      setCourseData(coursesResponse.data || []);
      
      setSnackbar({
        open: true,
        message: 'Course assigned successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving course assignment:', error);
      setSnackbar({
        open: true,
        message: 'Failed to assign course',
        severity: 'error'
      });
    }
  };

  const handleSaveProfile = async () => {
    try {
      const response = await updateUserMe(updatedProfile);
      setStudentData({ ...studentData, ...response.data });
      setEditMode(false);
      setSnackbar({
        open: true,
        message: 'Profile updated successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating profile:', err);
      setSnackbar({
        open: true,
        message: 'Failed to update profile',
        severity: 'error'
      });
    }
  };

  const handleEditFees = () => {
    setEditingFees(true);
  };

  const handleMaterialClick = (material) => {
    setSelectedMaterial(material);
  };

  const handleCloseMaterialDialog = () => {
    setSelectedMaterial(null);
  };

  const handleSaveFees = async () => {
    try {
      // Transform the fee data before sending
      const feeData = {
        admission_date: updatedFees.admission_date ? 
          dayjs(updatedFees.admission_date).format('YYYY-MM-DD') : null,
        fee_paid: updatedFees.fee_paid || 0,
        fee_due: updatedFees.fee_due || 0,
        fee_due_date: updatedFees.fee_due_date ? 
          dayjs(updatedFees.fee_due_date).format('YYYY-MM-DD') : null,
        fee_method: updatedFees.fee_method || ''
      };
  
      const response = await updateStudentFields(studentId, feeData);
      setStudentData({ ...studentData, ...response.data });
      setEditingFees(false);
      setSnackbar({
        open: true,
        message: 'Fee details updated successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating fees:', err);
      setSnackbar({
        open: true,
        message: err.response?.data?.message || 'Failed to update fee details',
        severity: 'error'
      });
    }
  };

  const handleClearDueDate = () => {
    setUpdatedFees(prev => ({
      ...prev,
      fee_due_date: null
    }));
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    console.log(`File selected for ${type}:`, {
      name: file.name,
      type: file.type,
      size: file.size
    });

    if (type === 'image_attachment' && !file.type.startsWith('image/')) {
      setSnackbar({
        open: true,
        message: 'Please select a valid image file',
        severity: 'error'
      });
      return;
    }

    setNewNote(prev => ({
      ...prev,
      [type]: file
    }));
  };

  const validateFile = (file, type) => {
    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      setSnackbar({
        open: true,
        message: 'File size should not exceed 10MB',
        severity: 'error'
      });
      return false;
    }

    if (type === 'image_attachment') {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validImageTypes.includes(file.type)) {
        setSnackbar({
          open: true,
          message: 'Please select a valid image file (JPEG, PNG, or GIF)',
          severity: 'error'
        });
        return false;
      }
    }

    return true;
  };

  const handleAddNote = async () => {
    try {
      const formData = new FormData();
      
      formData.append('title', newNote.title.trim());
      formData.append('content', newNote.content.trim());
      
      if (newNote.file_attachment && validateFile(newNote.file_attachment, 'file_attachment')) {
        formData.append('file_attachment', newNote.file_attachment);
      }
      
      if (newNote.image_attachment && validateFile(newNote.image_attachment, 'image_attachment')) {
        formData.append('image_attachment', newNote.image_attachment);
      }

      const response = await addStudentNote(studentId, formData);
      setNotes([...notes, response.data]);
      setNoteDialogOpen(false);
      setNewNote({
        title: '',
        content: '',
        file_attachment: null,
        image_attachment: null
      });
      setSnackbar({
        open: true,
        message: 'Note added successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error adding note:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to add note',
        severity: 'error'
      });
    }
  };

  const handleEditNote = (note) => {
    setEditingNote({
      title: note.title,
      content: note.content,
      file_attachment: null,
      image_attachment: null
    });
    setEditingNoteId(note.id);
  };

  const handleCancelEdit = () => {
    setEditingNote(null);
    setEditingNoteId(null);
  };

  const handleUpdateNote = async (noteId) => {
    try {
      const formData = new FormData();
      formData.append('title', editingNote.title.trim());
      formData.append('content', editingNote.content.trim());
      
      if (editingNote.file_attachment instanceof File) {
        formData.append('file_attachment', editingNote.file_attachment);
      }
      
      if (editingNote.image_attachment instanceof File) {
        formData.append('image_attachment', editingNote.image_attachment);
      }

      const response = await updateStudentNote(studentId, noteId, formData);
      setNotes(notes.map(note => 
        note.id === noteId ? response.data : note
      ));

      setEditingNote(null);
      setEditingNoteId(null);
      setSelectedFile(null);

      setSnackbar({
        open: true,
        message: 'Note updated successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating note:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to update note',
        severity: 'error'
      });
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await deleteStudentNote(studentId, noteId);
      setNotes(notes.filter(note => note.id !== noteId));
      setSnackbar({
        open: true,
        message: 'Note deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting note:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to delete note',
        severity: 'error'
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleApplyHold = async () => {
    try {
      const response = await createCourseHold({
        student: studentId,
        start_date: newHold.start_date.format('YYYY-MM-DD'),
        end_date: newHold.end_date.format('YYYY-MM-DD'),
        reason: newHold.reason,
      });
      setHoldHistory([...holdHistory, response.data]);
      setSnackbar({
        open: true,
        message: 'Hold applied successfully',
        severity: 'success'
      });
      setNewHold({ start_date: null, end_date: null, reason: '' });
    } catch (error) {
      console.error('Error applying hold:', error);
      setSnackbar({
        open: true,
        message: 'Failed to apply hold',
        severity: 'error'
      });
    }
  };

  // Course Flow Management Functions
  const handleAddFlowItem = async (itemId) => {
    if (!selectedFlow) return;

    try {
      await customizeStudentFlow(selectedFlow, [itemId], []);
      
      const response = await getStudentCourseFlow(selectedFlow);
      const items = response.data.flow_items.map(item => item.syllabus_item);
      setCurrentFlowItems(items);
      
      setSnackbar({
        open: true,
        message: 'Item added successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error adding item:', err);
      setSnackbar({
        open: true,
        message: 'Failed to add item',
        severity: 'error'
      });
    }
  };

  const handleRemoveFlowItem = async (itemId) => {
    if (!selectedFlow) return;

    try {
      await customizeStudentFlow(selectedFlow, [], [itemId]);
      
      const response = await getStudentCourseFlow(selectedFlow);
      const items = response.data.flow_items.map(item => item.syllabus_item);
      setCurrentFlowItems(items);
      
      setSnackbar({
        open: true,
        message: 'Item removed successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error removing item:', err);
      setSnackbar({
        open: true,
        message: 'Failed to remove item',
        severity: 'error'
      });
    }
  };

  const handleItemClick = (itemId) => {
    setExpandedItem(expandedItem === itemId ? null : itemId);
  };

  // Component Functions
  const CourseFlowSection = () => (
    <GlassContainer>
      <Typography variant="h6" gutterBottom color="white">
        Customize Course Flow
      </Typography>

      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <InputLabel id="flow-select-label" sx={{ color: 'white' }}>Select Course Flow</InputLabel>
        <Select
          labelId="flow-select-label"
          value={selectedFlow || ''}
          onChange={(e) => setSelectedFlow(e.target.value)}
          label="Select Course Flow"
          sx={{
            color: 'white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 255, 255, 0.87)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
          }}
        >
          {courseFlowData.map(flow => (
            <MenuItem key={flow.id} value={flow.id}>
              {flow.base_course_flow.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedFlow && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              Available Items
            </Typography>
            <List>
              {availableItems
                .filter(item => !currentFlowItems.some(current => current.id === item.id))
                .map(item => (
                  <Box key={item.id} sx={{ mb: 2 }}>
                    <ListItem 
                      sx={{
                        border: '1px solid rgba(255, 255, 255, 0.12)',
                        borderRadius: expandedItem === item.id ? '8px 8px 0 0' : '8px',
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleItemClick(item.id)}
                    >
                      <ListItemText 
                        primary={
                          <Typography color="white" sx={{ fontWeight: 'bold' }}>
                            {item.title}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton 
                          edge="end" 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddFlowItem(item.id);
                          }}
                          sx={{ color: 'white' }}
                        >
                          <AddIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {expandedItem === item.id && (
                      <Box 
                        sx={{ 
                          p: 2,
                          bgcolor: 'rgba(255, 255, 255, 0.03)',
                          borderRadius: '0 0 8px 8px',
                          border: '1px solid rgba(255, 255, 255, 0.12)',
                          borderTop: 'none',
                        }}
                      >
                        <Typography variant="subtitle2" color="white" gutterBottom>
                          Contents:
                        </Typography>
                        {item.item_contents?.length > 0 ? (
                          item.item_contents.map((content) => (
                            <Box 
                              key={content.id} 
                              sx={{ 
                                pl: 2, 
                                py: 0.5,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography color="rgba(255, 255, 255, 0.7)" variant="body2">
                                {content.content.content_type}
                              </Typography>
                              {content.content.content_type === 'QUIZ' && (
                                <Typography color="rgba(255, 255, 255, 0.6)" variant="body2">
                                  {content.content.questions?.length || 0} Questions
                                </Typography>
                              )}
                            </Box>
                          ))
                        ) : (
                          <Typography color="rgba(255, 255, 255, 0.5)" variant="body2">
                            No contents available
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              Current Flow Items
            </Typography>
            <List>
              {currentFlowItems.map(item => (
                <Box key={item.id} sx={{ mb: 2 }}>
                  <ListItem 
                    sx={{
                      border: '1px solid rgba(255, 255, 255, 0.12)',
                      borderRadius: expandedItem === item.id ? '8px 8px 0 0' : '8px',
                      bgcolor: 'rgba(255, 255, 255, 0.05)',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleItemClick(item.id)}
                  >
                    <ListItemText 
                      primary={
                        <Typography color="white" sx={{ fontWeight: 'bold' }}>
                          {item.title}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFlowItem(item.id);
                        }}
                        sx={{ color: 'white' }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {expandedItem === item.id && (
                    <Box 
                      sx={{ 
                        p: 2,
                        bgcolor: 'rgba(255, 255, 255, 0.03)',
                        borderRadius: '0 0 8px 8px',
                        border: '1px solid rgba(255, 255, 255, 0.12)',
                        borderTop: 'none',
                      }}
                    >
                      <Typography variant="subtitle2" color="white" gutterBottom>
                        Contents:
                      </Typography>
                      {item.item_contents?.length > 0 ? (
                        item.item_contents.map((content) => (
                          <Box 
                            key={content.id} 
                            sx={{ 
                              pl: 2, 
                              py: 0.5,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <Typography color="rgba(255, 255, 255, 0.7)" variant="body2">
                              {content.content.content_type}
                            </Typography>
                            {content.content.content_type === 'QUIZ' && (
                             <Typography color="rgba(255, 255, 255, 0.6)" variant="body2">
                             {content.content.questions?.length || 0} Questions
                           </Typography>
                         )}
                       </Box>
                     ))
                   ) : (
                     <Typography color="rgba(255, 255, 255, 0.5)" variant="body2">
                       No contents available
                     </Typography>
                   )}
                 </Box>
               )}
             </Box>
           ))}
         </List>
       </Grid>
     </Grid>
   )}
 </GlassContainer>
);

const TabPanel = (props) => {
 const { children, value, index, ...other } = props;

 return (
   <div
     role="tabpanel"
     hidden={value !== index}
     id={`simple-tabpanel-${index}`}
     aria-labelledby={`simple-tab-${index}`}
     {...other}
   >
     {value === index && (
       <Box p={3}>
         {children}
       </Box>
     )}
   </div>
 );
};

// Main Render
if (loading) {
 return (
   <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
     <CircularProgress sx={{ color: 'white' }} />
   </Box>
 );
}

if (error) {
 return (
   <GlassContainer maxWidth="sm">
     <Alert severity="error">{error}</Alert>
   </GlassContainer>
 );
}

return (
 <GlassContainer sx={{ 
   paddingBottom: '50px',
   margin: { xs: 1, sm: 2, md: 3 },
   minHeight: '100vh'
 }}>
   {/* Header Section */}
   <Box mb={4} display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'center' : 'flex-start'} justifyContent="space-between">
     <Typography variant="h4" gutterBottom color="white" align={isMobile ? 'center' : 'left'}>
       Student Profile
     </Typography>
   </Box>

   <Grid container spacing={3}>
     {/* Left Column - Basic Info and Profile */}
     <Grid item xs={12} md={4}>
       <Stack spacing={3}>
         {/* Profile Card */}
         <GlassCard>
           <CardContent>
             <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
               <Avatar
                 src={studentData?.profile_image}
                 alt={studentData?.full_name}
                 sx={{ width: 120, height: 120, mb: 2 }}
               />
               <Typography variant="h6" color="white">
                 {studentData?.full_name}
               </Typography>
               <Chip 
                 label={studentData?.role} 
                 sx={{ 
                   bgcolor: 'rgba(255, 255, 255, 0.1)',
                   color: 'white',
                   mt: 1 
                 }}
               />
             </Box>
             <Box mb={4} display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'center' : 'flex-start'} justifyContent="space-between">
        
        
        
          <GradientButton
            startIcon={<AddIcon />}
            onClick={handleAssignCourse}
          >
            Assign Course
          </GradientButton>
       
      </Box>

             {editMode ? (
               <Stack spacing={2}>
                 <StyledTextField
                   fullWidth
                   label="First Name"
                   value={updatedProfile.first_name}
                   onChange={(e) => setUpdatedProfile({ ...updatedProfile, first_name: e.target.value })}
                 />
                 <StyledTextField
                   fullWidth
                   label="Last Name"
                   value={updatedProfile.last_name}
                   onChange={(e) => setUpdatedProfile({ ...updatedProfile, last_name: e.target.value })}
                 />
                 <StyledTextField
                   fullWidth
                   label="Email"
                   value={updatedProfile.email}
                   onChange={(e) => setUpdatedProfile({ ...updatedProfile, email: e.target.value })}
                 />
                 <StyledTextField
                   fullWidth
                   label="Phone"
                   value={updatedProfile.phone}
                   onChange={(e) => setUpdatedProfile({ ...updatedProfile, phone: e.target.value })}
                 />
                 <StyledTextField
                   fullWidth
                   label="City"
                   value={updatedProfile.city}
                   onChange={(e) => setUpdatedProfile({ ...updatedProfile, city: e.target.value })}
                 />
                 <StyledTextField
                   fullWidth
                   multiline
                   rows={3}
                   label="About Me"
                   value={updatedProfile.about_me}
                   onChange={(e) => setUpdatedProfile({ ...updatedProfile, about_me: e.target.value })}
                 />
                 <DatePicker
                   label="Date of Birth"
                   value={updatedProfile.date_of_birth ? dayjs(updatedProfile.date_of_birth) : null}
                   onChange={(date) => setUpdatedProfile({ ...updatedProfile, date_of_birth: date?.format('YYYY-MM-DD') })}
                   renderInput={(params) => <StyledTextField {...params} fullWidth />}
                 />
                 <Box display="flex" gap={1}>
                   <GradientButton 
                     startIcon={<SaveIcon />} 
                     onClick={handleSaveProfile} 
                     fullWidth
                   >
                     Save
                   </GradientButton>
                   <GradientButton 
                     startIcon={<CancelIcon />} 
                     onClick={() => setEditMode(false)}
                     fullWidth
                     sx={{ 
                       background: 'linear-gradient(45deg, #666, #999)',
                       '&:hover': {
                         background: 'linear-gradient(45deg, #999, #666)'
                       }
                     }}
                   >
                     Cancel
                   </GradientButton>
                 </Box>
               </Stack>
             ) : (
               <>
                 <List>
                   <ListItem>
                     <ListItemText 
                       primary={<Typography color="rgba(255,255,255,0.7)">Email</Typography>}
                       secondary={<Typography color="white">{studentData?.email}</Typography>}
                     />
                   </ListItem>
                   <ListItem>
                     <ListItemText 
                       primary={<Typography color="rgba(255,255,255,0.7)">Phone</Typography>}
                       secondary={<Typography color="white">{studentData?.phone}</Typography>}
                     />
                   </ListItem>
                   <ListItem>
                     <ListItemText 
                       primary={<Typography color="rgba(255,255,255,0.7)">City</Typography>}
                       secondary={<Typography color="white">{studentData?.city}</Typography>}
                     />
                   </ListItem>
                   <ListItem>
                     <ListItemText 
                       primary={<Typography color="rgba(255,255,255,0.7)">Date of Birth</Typography>}
                       secondary={
                         <Typography color="white">
                           {studentData?.date_of_birth ? 
                             dayjs(studentData.date_of_birth).format('MMMM D, YYYY') : 
                             'Not set'}
                         </Typography>
                       }
                     />
                   </ListItem>
                   {studentData?.about_me && (
                     <ListItem>
                       <ListItemText 
                         primary={<Typography color="rgba(255,255,255,0.7)">About</Typography>}
                         secondary={<Typography color="white">{studentData.about_me}</Typography>}
                       />
                     </ListItem>
                   )}
                 </List>
                 <Box display="flex" justifyContent="center" mt={2}>
                   
                 </Box>
               </>
             )}
           </CardContent>
         </GlassCard>

         {/* Fee Information Card */}
         
    <GlassCard>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="white">
            <MoneyIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
            Fee Information
          </Typography>
          {(userRole === 'ADMIN' || userRole === 'MANAGER') && (
            <IconButton onClick={handleEditFees} sx={{ color: 'white' }}>
              <EditIcon />
            </IconButton>
          )}
        </Box>

        {editingFees ? (
          <Stack spacing={2}>
            <DatePicker
              label="Admission Date"
              value={updatedFees.admission_date ? dayjs(updatedFees.admission_date) : null}
              onChange={(date) => setUpdatedFees(prev => ({
                ...prev,
                admission_date: date
              }))}
              renderInput={(params) => (
                <StyledTextField 
                  {...params} 
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {updatedFees.admission_date && (
                          <IconButton
                            size="small"
                            onClick={() => setUpdatedFees(prev => ({ ...prev, admission_date: null }))}
                          >
                            <CancelIcon />
                          </IconButton>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            
            <StyledTextField
              fullWidth
              label="Fee Paid"
              type="number"
              value={updatedFees.fee_paid}
              onChange={(e) => setUpdatedFees(prev => ({
                ...prev,
                fee_paid: e.target.value
              }))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MoneyIcon sx={{ color: 'rgba(255,255,255,0.7)' }} />
                  </InputAdornment>
                ),
              }}
            />

            <StyledTextField
              fullWidth
              label="Fee Due"
              type="number"
              value={updatedFees.fee_due}
              onChange={(e) => setUpdatedFees(prev => ({
                ...prev,
                fee_due: e.target.value
              }))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MoneyIcon sx={{ color: 'rgba(255,255,255,0.7)' }} />
                  </InputAdornment>
                ),
              }}
            />

<DatePicker
  label="Due Date"
  value={updatedFees.fee_due_date ? dayjs(updatedFees.fee_due_date) : null}
  onChange={(date) => setUpdatedFees(prev => ({
    ...prev,
    fee_due_date: date
  }))}
  renderInput={(params) => (
    <StyledTextField 
      {...params} 
      fullWidth
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {updatedFees.fee_due_date && (
              <IconButton
                size="small"
                onClick={() => setUpdatedFees(prev => ({ ...prev, fee_due_date: null }))}
                sx={{ 
                  color: 'white',
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            )}
            {params.InputProps.endAdornment}
          </Box>
        ),
      }}
    />
  )}
/>

            <StyledTextField
              fullWidth
              label="Payment Method"
              select
              value={updatedFees.fee_method}
              onChange={(e) => setUpdatedFees(prev => ({
                ...prev,
                fee_method: e.target.value
              }))}
            >
              <MenuItem value="CASH">Cash</MenuItem>
              <MenuItem value="UPI-Abhinav">UPI-Abhinav</MenuItem>
              <MenuItem value="UPI-Shahab">UPI-Shahab</MenuItem>
              <MenuItem value="UPI-Meghna">UPI-Meghna</MenuItem>
              <MenuItem value="UPI-Ansh">UPI-Ansh</MenuItem>
              <MenuItem value="UPI-Nisha">UPI-Nisha</MenuItem>
              <MenuItem value="UPI-Chandan">UPI-Chandan</MenuItem>
              <MenuItem value="UPI-Other">UPI-Other</MenuItem>
              <MenuItem value="Razor">Razor</MenuItem>
              <MenuItem value="Company Account">Company Account</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </StyledTextField>

            <Box display="flex" gap={1}>
              <GradientButton 
                startIcon={<SaveIcon />} 
                onClick={handleSaveFees}
                fullWidth
              >
                Save
              </GradientButton>
              <GradientButton 
                startIcon={<CancelIcon />}
                onClick={() => setEditingFees(false)}
                fullWidth
                sx={{ 
                  background: 'linear-gradient(45deg, #666, #999)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #999, #666)'
                  }
                }}
              >
                Cancel
              </GradientButton>
            </Box>
          </Stack>
        ) : (
          <List>
            <ListItem>
              <ListItemText 
                primary={<Typography color="rgba(255,255,255,0.7)">Admission Date</Typography>}
                secondary={
                  <Typography color="white">
                    {studentData?.admission_date ? 
                      dayjs(studentData.admission_date).format('MMMM D, YYYY') : 
                      'Not set'}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary={<Typography color="rgba(255,255,255,0.7)">Fee Paid</Typography>}
                secondary={
                  <Typography color="white">
                    ₹{Number(studentData?.fee_paid || 0).toLocaleString()}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary={<Typography color="rgba(255,255,255,0.7)">Fee Due</Typography>}
                secondary={
                  <Typography color="white">
                    ₹{Number(studentData?.fee_due || 0).toLocaleString()}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary={<Typography color="rgba(255,255,255,0.7)">Due Date</Typography>}
                secondary={
                  <Typography color="white">
                    {studentData?.fee_due_date ? 
                      dayjs(studentData.fee_due_date).format('MMMM D, YYYY') : 
                      'No due date set'}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary={<Typography color="rgba(255,255,255,0.7)">Payment Method</Typography>}
                secondary={
                  <Typography color="white">
                    {studentData?.fee_method ? 
                      studentData.fee_method.replace('_', ' ').toUpperCase() : 
                      'Not specified'}
                  </Typography>
                }
              />
            </ListItem>
            {/* Add total calculation */}
           
          </List>
        )}
      </CardContent>
    </GlassCard>
  
         {/* Notes Card */}
<GlassCard>
  <CardContent>
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Typography variant="h6" color="white">
        <NoteIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
        Notes
      </Typography>
      {(userRole === 'ADMIN' || userRole === 'MANAGER') && (
        <GradientButton
          startIcon={<AddIcon />}
          onClick={() => setNoteDialogOpen(true)}
        >
          Add Note
        </GradientButton>
      )}
    </Box>
    {notes.length > 0 ? (
      notes.map((note) => (
        <GlassContainer key={note.id} sx={{ mb: 2 }}>
          {editingNoteId === note.id ? (
            // Editing mode
            <Box sx={{ p: 2 }}>
              <StyledTextField
                fullWidth
                label="Title"
                value={editingNote.title}
                onChange={(e) => setEditingNote({
                  ...editingNote,
                  title: e.target.value
                })}
                sx={{ mb: 2 }}
              />
              <StyledTextField
                fullWidth
                multiline
                rows={4}
                label="Content"
                value={editingNote.content}
                onChange={(e) => setEditingNote({
                  ...editingNote,
                  content: e.target.value
                })}
                sx={{ mb: 2 }}
              />
              <Box>
                <input
                  accept=".pdf,.doc,.docx"
                  style={{ display: 'none' }}
                  id={`edit-file-attachment-${note.id}`}
                  type="file"
                  onChange={(e) => handleFileChange(e, 'file_attachment')}
                />
                <label htmlFor={`edit-file-attachment-${note.id}`}>
                  <GradientButton
                    component="span"
                    startIcon={<FileIcon />}
                    sx={{ mr: 1 }}
                  >
                    {editingNote.file_attachment ? 'Change File' : 'Attach File'}
                  </GradientButton>
                </label>
                {editingNote.file_attachment && (
                  <Typography color="white" variant="body2" sx={{ mt: 1 }}>
                    Selected file: {editingNote.file_attachment.name}
                    <IconButton
                      size="small"
                      onClick={() => setEditingNote({
                        ...editingNote,
                        file_attachment: null
                      })}
                      sx={{ color: 'white', ml: 1 }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Typography>
                )}
              </Box>
              <Box display="flex" gap={1} mt={2}>
                <GradientButton
                  onClick={() => handleUpdateNote(note.id)}
                  startIcon={<SaveIcon />}
                >
                  Save
                </GradientButton>
                <GradientButton
                  onClick={handleCancelEdit}
                  startIcon={<CancelIcon />}
                  sx={{
                    background: 'linear-gradient(45deg, #666, #999)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #999, #666)'
                    }
                  }}
                >
                  Cancel
                </GradientButton>
              </Box>
            </Box>
          ) : (
            // View mode
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" color="white" gutterBottom>
                {note.title}
              </Typography>
              <Typography color="rgba(255,255,255,0.7)" gutterBottom>
                {note.content}
              </Typography>
              <Box mt={1} display="flex" flexDirection="column" gap={1}>
                {/* File Attachment */}
                {note.file_attachment && (
                  <Chip
                    icon={<FileIcon />}
                    label="File Attachment"
                    component="a"
                    href={getImageUrl(note.file_attachment)}
                    target="_blank"
                    clickable
                    sx={{
                      color: 'white',
                      '& .MuiChip-icon': { color: 'white' }
                    }}
                  />
                )}
                
                {/* Image Attachment */}
                {note.image_attachment && (
                  <Box>
                    <Chip
                      icon={<ImageIcon />}
                      label="Image Attachment"
                      component="a"
                      href={getImageUrl(note.image_attachment)}
                      target="_blank"
                      clickable
                      sx={{
                        color: 'white',
                        '& .MuiChip-icon': { color: 'white' },
                        mb: 1
                      }}
                    />
                    <Box sx={{ maxWidth: '200px', mt: 1 }}>
                      <img
                        src={getImageUrl(note.image_attachment)}
                        alt="Attached"
                        style={{ 
                          width: '100%', 
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }}
                        onClick={() => window.open(getImageUrl(note.image_attachment), '_blank')}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <Typography variant="caption" color="rgba(255,255,255,0.5)">
                  Added by {note.created_by_name} on {dayjs(note.created_at).format('MMM D, YYYY HH:mm')}
                </Typography>
                {(userRole === 'ADMIN' || userRole === 'MANAGER') && (
                  <Box>
                    <IconButton
                      onClick={() => handleEditNote(note)}
                      sx={{ color: 'white' }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteNote(note.id)}
                      sx={{ color: 'white' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </GlassContainer>
      ))
    ) : (
      <Typography color="rgba(255,255,255,0.5)" align="center">
        No notes available
      </Typography>
    )}
  </CardContent>
</GlassCard>
       </Stack>
     </Grid>

     {/* Right Column - Course Info and Other Details */}
     <Grid item xs={12} md={8}>
       <Stack spacing={3}>
       
         {/* Course Information */}
         <GlassCard>
           <CardContent>
             <Typography variant="h6" color="white" gutterBottom>
               <CalendarIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
               Active Courses
             </Typography>
             <Grid container spacing={2}>
               {courseData.map((course) => (
                 <Grid item xs={12} sm={6} key={course.id}>
                   <GlassContainer sx={{ height: '100%' }}>
                     <Typography variant="subtitle1" color="white" gutterBottom>
                       {course.course.name}
                     </Typography>
                     <Stack spacing={1}>
                       <Box display="flex" justifyContent="space-between">
                         <Typography color="rgba(255,255,255,0.7)">Start Date:</Typography>
                         <Typography color="white">
                           {dayjs(course.start_date).format('MMM D, YYYY')}
                         </Typography>
                       </Box>
                       <Box display="flex" justifyContent="space-between">
                         <Typography color="rgba(255,255,255,0.7)">End Date:</Typography>
                         <Typography color="white">
                           {dayjs(course.end_date).format('MMM D, YYYY')}
                         </Typography>
                       </Box>
                       <Box display="flex" justifyContent="space-between">
                         <Typography color="rgba(255,255,255,0.7)">Class Time:</Typography>
                            <Typography color="white">
                              {course.class_time || 'Not specified'}
                            </Typography>
                          </Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography color="rgba(255,255,255,0.7)">Trainer:</Typography>
                            <Typography color="white">
                              {course.trainer ? course.trainer.full_name : 'Not assigned'}
                            </Typography>
                          </Box>
                          {course.bbb_join_url && (
                            <Box mt={1}>
                              <GradientButton 
                                fullWidth
                                onClick={() => window.open(course.bbb_join_url, '_blank')}
                              >
                                Join Class Room
                              </GradientButton>
                            </Box>
                          )}
                        </Stack>
                      </GlassContainer>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </GlassCard>

            {/* Tabs Section */}
<Box mt={4} sx={{
  '.MuiTabs-root': {
    maxWidth: '100%',
    overflow: 'hidden',
  },
  '.MuiTabs-scroller': {
    overflow: 'auto !important',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '4px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },

  }}>
  <StyledTabs 
    value={tabValue} 
    onChange={handleTabChange} 
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
    sx={{
      borderBottom: 1,
      borderColor: 'divider',
      mb: 2,
    }}
  >
     <Tab label="Attendance History" sx={{ minWidth: 'auto' }} />
  
    <Tab label="Study Materials" sx={{ minWidth: 'auto' }} />
    <Tab label="Feedback History" sx={{ minWidth: 'auto' }} />
    <Tab label="Hold History" sx={{ minWidth: 'auto' }} />
    <Tab label="Class Content" sx={{ minWidth: 'auto' }} />
    <Tab label="Progress" sx={{ minWidth: 'auto' }} />
    <Tab label="Course Flow" sx={{ minWidth: 'auto' }} />
    <Tab label="Assignment History" sx={{ minWidth: 'auto' }} />
  </StyledTabs>


              {/* Class Content Tab */}
<TabPanel value={tabValue} index={4}>
  <Typography variant="h6" gutterBottom color="white">Class Content</Typography>
  <Box sx={{ 
    height: '400px', // Fixed height to show approximately 5 rows
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '4px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  }}>
    <List>
      {attendanceData.map((attendance) => (
        <ListItem key={attendance.id}>
          <ListItemText
            primary={<Typography color="white">{`Date: ${dayjs(attendance.timestamp).format('MMMM D, YYYY HH:mm')}`}</Typography>}
            secondary={
              <>
                <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.7)">
                  Class Content: {attendance.class_content || 'No content available'}
                </Typography>
                <br />
                <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.5)">
                  Student Feedback: {attendance.student_feedback}
                </Typography>
              </>
            }
          />
          <Chip 
            label={attendance.status} 
            color={attendance.status === 'PRESENT' ? 'success' : 'default'}
            sx={{ color: 'white', borderColor: 'white' }}
          />
        </ListItem>
      ))}
    </List>
  </Box>
</TabPanel>

             {/* Study Materials Tab */}
<TabPanel value={tabValue} index={1}>
  <Typography variant="h6" gutterBottom color="white">Study Materials</Typography>
  <Box sx={{ 
    height: '400px', // Fixed height to show approximately 5 rows
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '4px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  }}>
    <List>
      {studyMaterials.map((material) => (
        <ListItem 
          key={material.id} 
          button 
          onClick={() => handleMaterialClick(material)}
        >
          <ListItemIcon>
            <DescriptionIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText 
            primary={<Typography color="white">{material.topic}</Typography>}
            secondary={
              <Typography color="rgba(255, 255, 255, 0.7)">
                Available until: {dayjs(material.expiry_date).format('MMMM D, YYYY')}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Box>
</TabPanel>

              {/* Feedback History Tab */}
              <TabPanel value={tabValue} index={2}>
                <Typography variant="h6" gutterBottom color="white">Feedback History</Typography>
                <List>
                  {feedbackHistory.map((feedback) => (
                    <ListItem key={feedback.id}>
                      <ListItemText
                        primary={<Typography color="white">{feedback.topic}</Typography>}
                        secondary={
                          <>
                            <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.7)">
                              {feedback.content}
                            </Typography>
                            <br />
                            <Typography component="span" variant="body2" color="rgba(255, 255, 255, 0.5)">
                              Status: {feedback.status}
                            </Typography>
                          </>
                        }
                      />
                      <Chip 
                        label={feedback.status} 
                        color={feedback.status === 'RESOLVED' ? 'success' : 'default'}
                        sx={{ color: 'white', borderColor: 'white' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </TabPanel>

              {/* Hold History Tab */}
              <TabPanel value={tabValue} index={3}>
                <Typography variant="h6" gutterBottom color="white">Hold History</Typography>
                <List>
                  {holdHistory.map((hold) => (
                    <ListItem key={hold.id}>
                      <ListItemText
                        primary={
                          <Typography color="white">
                            Hold from {dayjs(hold.start_date).format('MMMM D, YYYY')} to {dayjs(hold.end_date).format('MMMM D, YYYY')}
                          </Typography>
                        }
                        secondary={
                          <Typography color="rgba(255, 255, 255, 0.7)">
                            Reason: {hold.reason}
                          </Typography>
                        }
                      />
                      <Chip 
                        label={hold.status} 
                        color={hold.status === 'APPROVED' ? 'success' : 'default'}
                        sx={{ color: 'white', borderColor: 'white' }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom color="white">Apply New Hold</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="Hold Start Date"
                        value={newHold.start_date}
                        onChange={(date) => setNewHold({ ...newHold, start_date: date })}
                        renderInput={(params) => <StyledTextField {...params} fullWidth />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="Hold End Date"
                        value={newHold.end_date}
                        onChange={(date) => setNewHold({ ...newHold, end_date: date })}
                        renderInput={(params) => <StyledTextField {...params} fullWidth />}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Hold Reason"
                        value={newHold.reason}
                        onChange={(e) => setNewHold({ ...newHold, reason: e.target.value })}
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <GradientButton 
                        onClick={handleApplyHold} 
                        disabled={!newHold.start_date || !newHold.end_date || !newHold.reason}
                      >
                        Apply Hold
                      </GradientButton>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>

              {/* Attendance History Tab */}
              <TabPanel value={tabValue} index={0}>
                <Typography variant="h6" gutterBottom color="white">Attendance History</Typography>
                <AttendanceManagement
                  studentId={studentId}
                  trainerId={courseData[0]?.trainer?.id}
                  userRole={userRole}
                />
              </TabPanel>

              {/* Progress Tab */}
              <TabPanel value={tabValue} index={5}>
                <Typography variant="h6" gutterBottom color="white">Progress Tracking</Typography>
                <StudentProgress studentId={studentId} />
              </TabPanel>

              {/* Course Flow Tab */}
              <TabPanel value={tabValue} index={6}>
                {CourseFlowSection()}
              </TabPanel>

              {/* Assignment History Tab */}
              <TabPanel value={tabValue} index={7}>
                <Typography variant="h6" gutterBottom color="white">Trainer Assignment History</Typography>
                <Timeline position="alternate">
                  {assignmentHistory.map((record, index) => (
                    <TimelineItem key={record.id}>
                      <TimelineSeparator>
                        <TimelineDot sx={{ 
                          bgcolor: record.unassignment_date ? '#f44336' : '#4caf50',
                          color: 'white'
                        }}>
                          {record.unassignment_date ? <PersonIcon /> : <PersonAddIcon />}
                        </TimelineDot>
                        {index < assignmentHistory.length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>
                        <GlassContainer>
                          <Typography color="white" variant="h6" component="div">
                            {record.reason}
                          </Typography>
                          <Typography color="white">
                            Trainer: {record.trainer_name}
                          </Typography>
                          <Typography color="white">
                            Course: {record.course_name}
                          </Typography>
                          <Typography color="rgba(255, 255, 255, 0.7)">
                            Assigned: {dayjs(record.assignment_date).format('MMMM D, YYYY')}
                          </Typography>
                          {record.unassignment_date && (
                            <Typography color="rgba(255, 255, 255, 0.7)">
                              Unassigned: {dayjs(record.unassignment_date).format('MMMM D, YYYY')}
                            </Typography>
                          )}
                          {record.notes && (
                            <Typography color="rgba(255, 255, 255, 0.7)" variant="body2" sx={{ mt: 1 }}>
                              Notes: {record.notes}
                            </Typography>
                          )}
                        </GlassContainer>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </TabPanel>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      


      {/* Add Note Dialog */}
      <Dialog 
        open={noteDialogOpen} 
        onClose={() => setNoteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 18, 0.8)',
            backdropFilter: 'blur(10px)',
            color: 'white',
          },
        }}
      >
        <DialogTitle>Add New Note</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <StyledTextField
              fullWidth
              label="Title"
              value={newNote.title}
              onChange={(e) => setNewNote(prev => ({ ...prev, title: e.target.value }))}
            />
            <StyledTextField
              fullWidth
              label="Content"
              multiline
              rows={4}
              value={newNote.content}
              onChange={(e) => setNewNote(prev => ({ ...prev, content: e.target.value }))}
            />
            <Box>
              <input
                accept=".pdf,.doc,.docx"
                style={{ display: 'none' }}
                id="file-attachment"
                type="file"
                onChange={(e) => handleFileChange(e, 'file_attachment')}
              />
              <label htmlFor="file-attachment">
                <GradientButton 
                  component="span" 
                  startIcon={<FileIcon />}
                  sx={{ mr: 1 }}
                >
                  {newNote.file_attachment ? 'Change File' : 'Attach File'}
                </GradientButton>
              </label>
              {newNote.file_attachment && (
                <Typography color="white" variant="body2" sx={{ mt: 1 }}>
                  Selected file: {newNote.file_attachment.name}
                  <IconButton
                    size="small"
                    onClick={() => setNewNote(prev => ({ ...prev, file_attachment: null }))}
                    sx={{ color: 'white', ml: 1 }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Typography>
              )}
            </Box>
            <Box>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="image-attachment"
                type="file"
                onChange={(e) => handleFileChange(e, 'image_attachment')}
              />
              <label htmlFor="image-attachment">
                <GradientButton 
                  component="span" 
                  startIcon={<ImageIcon />}
                  sx={{ mr: 1 }}
                >
                  {newNote.image_attachment ? 'Change Image' : 'Attach Image'}
                </GradientButton>
              </label>
              {newNote.image_attachment && (
                <Box sx={{ mt: 1 }}>
                  <Typography color="white" variant="body2">
                    Selected image: {newNote.image_attachment.name}
                    <IconButton
                      size="small"
                      onClick={() => setNewNote(prev => ({ ...prev, image_attachment: null }))}
                      sx={{ color: 'white', ml: 1 }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Typography>
                  <Box sx={{ mt: 1, maxWidth: '200px' }}>
                    <img
                      src={URL.createObjectURL(newNote.image_attachment)}
                      alt="Preview"
                      style={{ width: '100%', borderRadius: '4px' }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={() => setNoteDialogOpen(false)}>
            Cancel
          </GradientButton>
          <GradientButton 
            onClick={handleAddNote}
            disabled={!newNote.title || !newNote.content}
          >
            Add Note
          </GradientButton>
        </DialogActions>
      </Dialog>

      {/* Study Material Dialog */}
      <Dialog
        open={Boolean(selectedMaterial)}
        onClose={handleCloseMaterialDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 18, 0.8)',
            backdropFilter: 'blur(10px)',
            color: 'white',
          },
        }}
      >
        <DialogTitle>{selectedMaterial?.topic}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedMaterial?.course?.name}</Typography>
          <Typography variant="body2">
            Available until: {dayjs(selectedMaterial?.expiry_date).format('MMMM D, YYYY')}
          </Typography>
          <List>
          {selectedMaterial?.files.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <DescriptionIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary={file.file_name} />
                <Box>
                  {file.file_type.startsWith('image/') ? (
                    <img src={file.file} alt={file.file_name} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                  ) : file.file_type === 'application/pdf' ? (
                    <iframe src={file.file} width="100%" height="500px" title={file.file_name} />
                  ) : (
                    <Typography variant="body2">Preview not available for this file type.</Typography>
                  )}
                </Box>
                <GradientButton href={file.file} target="_blank" rel="noopener noreferrer">
                  Download
                </GradientButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <GradientButton onClick={handleCloseMaterialDialog}>
            Close
          </GradientButton>
        </DialogActions>
      </Dialog>
      <AddEditStudentModal
        open={openAssignModal}
        onClose={() => setOpenAssignModal(false)}
        student={studentData}
        onSave={handleSaveAssignment}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassContainer>
  );
};

export default StudentDetailsBig;