import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  styled,
  Snackbar,
  Alert,
  Autocomplete,
  Box,
  Chip,Backdrop
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { 
  listCourses, 
  listTrainers, 
  register, 
  createStudentCourse,
  updateStudentCourse,
  listStudentCourses,
  listCoordinators,
  createCourseHold,
  assignCoordinator,
  removeCoordinator,unassignStudentFromCourse
} from '../api';
import debounce from 'lodash/debounce';

const GradientBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #1a237e, #311b92, #4a148c)',
});
const LoadingOverlay = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  backdropFilter: 'blur(4px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
}));

const LoadingMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginTop: '1rem',
  textAlign: 'center',
}));
const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(20px)',
    borderRadius: '16px',
    color: theme.palette.common.white,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GlassInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.common.white,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.common.white,
  },
}));

const GlassSelect = styled(Select)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSelect-icon': {
    color: theme.palette.common.white,
  },
  '& .MuiSelect-select': {
    color: theme.palette.common.white,
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

function AddEditStudentModal({ open, onClose, student, onSave }) {
  const [studentData, setStudentData] = useState({
    username: '',
    email: '',
    password: '',
    full_name: '',
  });
  const [courses, setCourses] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [editingCourse, setEditingCourse] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const [selectedCoordinator, setSelectedCoordinator] = useState('');
  const [courseHold, setCourseHold] = useState({
    start_date: null,
    end_date: null,
    reason: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [loadingMessage, setLoadingMessage] = useState('');

  const [trainerSearchTerm, setTrainerSearchTerm] = useState('');
  const [isSearchingTrainers, setIsSearchingTrainers] = useState(false);
  const [trainerPage, setTrainerPage] = useState(1);
  const [hasMoreTrainers, setHasMoreTrainers] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setLoadingMessage('Loading courses and user data...');
    setError('');
    try {
      const [coursesResponse, coordinatorsResponse] = await Promise.all([
        listCourses(),
        listCoordinators(),
      ]);
      setCourses(coursesResponse.data || []);
      setCoordinators(coordinatorsResponse || []);

      setLoadingMessage('Loading trainers...');
      await fetchTrainers();

      if (student && (student.id || student.user?.id)) {
        setLoadingMessage('Loading student courses...');
        const studentId = student.user?.id || student.id;
        const studentCoursesResponse = await listStudentCourses({ student: studentId });
        setStudentCourses(studentCoursesResponse.data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
      setCourses([]);
      setTrainers([]);
      setCoordinators([]);
      setStudentCourses([]);
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  }, [student]);

  const fetchTrainers = useCallback(async (search = '', page = 1) => {
    setIsSearchingTrainers(true);
    try {
      const response = await listTrainers({ search, page, page_size: 10 });
      if (page === 1) {
        setTrainers(response.results || []);
      } else {
        setTrainers(prevTrainers => [...prevTrainers, ...(response.results || [])]);
      }
      setHasMoreTrainers(response.next !== null);
      setTrainerPage(page);
    } catch (error) {
      console.error('Error fetching trainers:', error);
      setError('Failed to fetch trainers. Please try again.');
    } finally {
      setIsSearchingTrainers(false);
    }
  }, []);

  const debouncedFetchTrainers = useCallback(
    debounce((searchTerm) => {
      setTrainerPage(1);
      fetchTrainers(searchTerm, 1);
    }, 300),
    [fetchTrainers]
  );

  const handleTrainerSearchChange = (event, newValue) => {
    setTrainerSearchTerm(newValue);
    if (newValue.length >= 2) {
      debouncedFetchTrainers(newValue);
    } else if (newValue.length === 0) {
      setTrainerPage(1);
      fetchTrainers('', 1);
    }
  };

  const handleTrainerScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight &&
      hasMoreTrainers &&
      !isSearchingTrainers
    ) {
      fetchTrainers(trainerSearchTerm, trainerPage + 1);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open, fetchData]);

  useEffect(() => {
    if (student) {
      setStudentData({
        username: student.username || student.user?.username || '',
        email: student.email || student.user?.email || '',
        full_name: student.full_name || student.user?.full_name || '',
      });
    } else {
      setStudentData({
        username: '',
        email: '',
        password: '',
        full_name: '',
      });
      setStudentCourses([]);
    }
  }, [student]);

  const handleStudentDataChange = (e) => {
    const { name, value } = e.target;
    setStudentData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAssignCoordinator = async (userId, coordinatorId) => {
    try {
      if (coordinatorId) {
        await assignCoordinator(userId, coordinatorId);
        setSnackbar({ open: true, message: 'Coordinator assigned successfully', severity: 'success' });
      } else {
        await removeCoordinator(userId);
        setSnackbar({ open: true, message: 'Coordinator removed successfully', severity: 'success' });
      }
      fetchData(); // Refresh data after assignment
    } catch (error) {
      console.error('Error assigning/removing coordinator:', error);
      setSnackbar({ open: true, message: 'Failed to assign/remove coordinator. Please try again.', severity: 'error' });
    }
  };

  const handleEditCourse = (course) => {
    setEditingCourse({
      id: course.id,
      course_id: course.course?.id || '',
      trainer_id: course.trainer?.id || '',
      coordinator_id: course.coordinator?.id || '',
      start_date: course.start_date ? dayjs(course.start_date) : null,
      end_date: course.end_date ? dayjs(course.end_date) : null,
      class_time: course.class_time || '',
    });
    setSelectedCourse(course.course);
  };

  const handleUnassignCourse = async (courseId) => {
    setLoading(true);
    setError('');

    try {
      await unassignStudentFromCourse(courseId);
      setStudentCourses(prevCourses => prevCourses.filter(c => c.id !== courseId));
      setSnackbar({ open: true, message: 'Student unassigned from course successfully', severity: 'success' });
    } catch (error) {
      console.error('Error unassigning student from course:', error);
      setError(`Failed to unassign student from course. ${error.message || 'Please try again.'}`);
      setSnackbar({ open: true, message: 'Failed to unassign student from course', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleAddNewCourse = () => {
    setEditingCourse({
      course_id: '',
      trainer_id: '',
      coordinator_id: '',
      start_date: null,
      end_date: null,
      class_time: '',
    });
    setSelectedCourse(null);
  };

  const handleCourseChange = (name, value) => {
    if (name === 'course_id') {
      const course = courses.find(c => c.id === parseInt(value));
      setSelectedCourse(course);
      setEditingCourse(prevCourse => ({
        ...prevCourse,
        course_id: parseInt(value),
        trainer_id: course?.is_group_class ? '' : prevCourse.trainer_id,
        class_time: course?.is_group_class ? '' : prevCourse.class_time,
      }));
    } else if (name === 'trainer_id') {
      setEditingCourse(prevCourse => ({
        ...prevCourse,
        trainer_id: value // This will now be the user ID
      }));
    } else {
      setEditingCourse(prevCourse => ({
        ...prevCourse,
        [name]: value
      }));
    }
  };

  const handleDateChange = (name, value) => {
    setEditingCourse(prevCourse => ({
      ...prevCourse,
      [name]: value
    }));
  };

  const handleSaveCourse = async () => {
    setLoading(true);
    setLoadingMessage('Saving course information...');
    setError('');


    try {
      const studentId = student.user?.id || student.id;
      const courseData = {
        student: studentId,
        course_id: editingCourse.course_id,
        start_date: editingCourse.start_date?.format('YYYY-MM-DD'),
        end_date: editingCourse.end_date?.format('YYYY-MM-DD'),
        coordinator_id: editingCourse.coordinator_id || null,
      };

      if (selectedCourse && !selectedCourse.is_group_class) {
        courseData.trainer_id = editingCourse.trainer_id || null;
        courseData.class_time = editingCourse.class_time;
      }

      let response;
      if (editingCourse.id) {
        response = await updateStudentCourse(editingCourse.id, courseData);
      } else {
        response = await createStudentCourse(courseData);
      }

      if (response && response.data) {
        setStudentCourses(prevCourses => {
          const updatedCourses = editingCourse.id
            ? prevCourses.map(c => c.id === editingCourse.id ? response.data : c)
            : [...prevCourses, response.data];
          return updatedCourses;
        });
        setEditingCourse(null);
        setSelectedCourse(null);
        setSnackbar({ open: true, message: 'Course saved successfully', severity: 'success' });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error saving student course:', error);
      if (error.response && error.response.data) {
        const errorMessages = Object.entries(error.response.data)
          .map(([key, value]) => `${key}: ${value.join(', ')}`)
          .join('; ');
        setError(`Failed to save student course. ${errorMessages}`);
      } else {
        setError(`Failed to save student course. ${error.message || 'Please try again.'}`);
      }
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingMessage('Saving student information...');
    setError('');


    try {
      let userId;

      if (student) {
        userId = student.user?.id || student.id;
      } else {
        const newUser = await register({
          ...studentData,
          role: 'STUDENT',
        });
        if (newUser && newUser.data && newUser.data.id) {
          userId = newUser.data.id;
        } else {
          throw new Error('Failed to create new user');
        }
      }

      onSave({ ...studentData, id: userId, courses: studentCourses });
      onClose();
    } catch (error) {
      console.error('Error saving student:', error);
      setError(`Failed to save student. ${error.message || 'Please try again.'}`);
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  };
  const handleApplyCourseHold = async () => {
    if (!editingCourse.id) {
      setError('Please save the course before applying a hold.');
      return;
    }

    try {
      const response = await createCourseHold({
        student_course: editingCourse.id,
        start_date: courseHold.start_date.format('YYYY-MM-DD'),
        end_date: courseHold.end_date.format('YYYY-MM-DD'),
        reason: courseHold.reason,
      });

      if (response && response.data) {
        setSnackbar({ open: true, message: 'Course hold applied successfully', severity: 'success' });
        setCourseHold({ start_date: null, end_date: null, reason: '' });
      }
    } catch (error) {
      console.error('Error applying course hold:', error);
      setError(`Failed to apply course hold. ${error.message || 'Please try again.'}`);
    }
  };

  const getTrainerName = (trainerId) => {
    if (!trainerId) return 'Not assigned';
    const trainer = trainers.find(t => t.user?.id === trainerId || t.id === trainerId);
    return trainer ? (trainer.user?.full_name || trainer.user?.username || `Trainer ${trainer.id}`) : 'Not assigned';
  };

  const getCoordinatorName = (coordinatorId) => {
    if (!coordinatorId) return 'Not assigned';
    const coordinator = coordinators.find(c => c.id === coordinatorId);
    return coordinator ? coordinator.name : 'Not assigned';
  };

  return (
    <>
      <GradientBackground />
      <GlassDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <LoadingOverlay open={loading}>
          <CircularProgress
            size={60}
            thickness={4}
            sx={{
              color: 'primary.main',
              '& .MuiCircularProgress-circle': {
                strokeLinecap: 'round',
              },
            }}
          />
          <LoadingMessage variant="h6">
            {loadingMessage || 'Loading...'}
          </LoadingMessage>
        </LoadingOverlay>
        <DialogTitle>
          <Typography variant="h5" color="white">
            {student ? 'Edit Student' : 'Add Student'}
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              {!student && (
                <>
                  <Grid item xs={12}>
                    <GlassInput
                      fullWidth
                      label="Username"
                      name="username"
                      value={studentData.username}
                      onChange={handleStudentDataChange}
                      required
                    />
                </Grid>
                  <Grid item xs={12}>
                    <GlassInput
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={studentData.email}
                      onChange={handleStudentDataChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GlassInput
                      fullWidth
                      label="Password"
                      name="password"
                      type="password"
                      value={studentData.password}
                      onChange={handleStudentDataChange}
                      required
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <GlassInput
                  fullWidth
                  label="Full Name"
                  name="full_name"
                  value={studentData.full_name}
                  onChange={handleStudentDataChange}
                  required
                />
              </Grid>
            </Grid>

            <Typography variant="h6" style={{ marginTop: '2rem', marginBottom: '1rem', color: 'white' }}>Enrolled Courses</Typography>
            {studentCourses && studentCourses.length > 0 ? (
              <GlassTableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Course</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Trainer</TableCell>
                      <TableCell>Coordinator</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Class Time</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentCourses.map((course) => (
                      <TableRow key={course.id}>
                        <TableCell>{course.course?.name || 'Unknown Course'}</TableCell>
                        <TableCell>{course.course?.is_group_class ? 'Group' : 'Individual'}</TableCell>
                        <TableCell>{course.trainer ? course.trainer.full_name : 'Not assigned'}</TableCell>
                        <TableCell>{course.coordinator ? getCoordinatorName(course.coordinator.id) : 'Not assigned'}</TableCell>
                        <TableCell>{course.start_date ? dayjs(course.start_date).format('MMM D, YYYY') : 'N/A'}</TableCell>
                        <TableCell>{course.end_date ? dayjs(course.end_date).format('MMM D, YYYY') : 'N/A'}</TableCell>
                        <TableCell>{course.class_time || 'Not set'}</TableCell>
                        <TableCell>
                        <IconButton onClick={() => handleEditCourse(course)}>
                          <EditIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <IconButton onClick={() => handleUnassignCourse(course.id)}>
                          <DeleteIcon sx={{ color: 'white' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GlassTableContainer>
          ) : (
            <Typography color="white">No courses enrolled yet.</Typography>
            )}
            
            <GradientButton 
              startIcon={<AddIcon />} 
              onClick={handleAddNewCourse}
              style={{ marginTop: '1rem' }}
            >
              Add New Course
            </GradientButton>

            {editingCourse && (
              <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="white">
                    {editingCourse.id ? 'Edit Course' : 'Add New Course'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel sx={{ color: 'white' }}>Course</InputLabel>
                    <GlassSelect
                      name="course_id"
                      value={editingCourse.course_id || ''}
                      onChange={(e) => handleCourseChange('course_id', e.target.value)}
                      required
                    >
                      {courses && courses.length > 0 ? (
                        courses.map((course) => (
                          <MenuItem key={course.id} value={course.id}>
                            {course.name} ({course.is_group_class ? 'Group' : 'Individual'})
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No courses available</MenuItem>
                      )}
                    </GlassSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Course Start Date"
                    value={editingCourse.start_date}
                    onChange={(newValue) => handleDateChange('start_date', newValue)}
                    renderInput={(params) => <GlassInput {...params} fullWidth required />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Course End Date"
                    value={editingCourse.end_date}
                    onChange={(newValue) => handleDateChange('end_date', newValue)}
                    renderInput={(params) => <GlassInput {...params} fullWidth required />}
                  />
                </Grid>
                {selectedCourse && !selectedCourse.is_group_class && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={trainers}
                        getOptionLabel={(option) => option.user.full_name || option.user.username}
                        renderInput={(params) => (
                          <GlassInput
                            {...params}
                            label="Trainer"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isSearchingTrainers ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        value={trainers.find(t => t.user.id === editingCourse.trainer_id) || null}
                        onChange={(event, newValue) => {
                          handleCourseChange('trainer_id', newValue ? newValue.user.id : null);
                        }}
                        onInputChange={handleTrainerSearchChange}
                        loading={isSearchingTrainers}
                        fullWidth
                        ListboxProps={{
                          onScroll: handleTrainerScroll,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <GlassInput
                        fullWidth
                        label="Class Time"
                        name="class_time"
                        type="time"
                        value={editingCourse.class_time || ''}
                        onChange={(e) => handleCourseChange('class_time', e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: 'white' }}>Coordinator</InputLabel>
                    <GlassSelect
                      value={selectedCoordinator}
                      onChange={(e) => setSelectedCoordinator(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {coordinators.map((coordinator) => (
                        <MenuItem key={coordinator.id} value={coordinator.id}>
                          {coordinator.name}
                        </MenuItem>
                      ))}
                    </GlassSelect>
                  </FormControl>
                  <GradientButton
                    onClick={() => handleAssignCoordinator(student.id, selectedCoordinator)}
                    style={{ marginTop: '1rem' }}
                  >
                    {selectedCoordinator ? 'Assign Coordinator' : 'Remove Coordinator'}
                  </GradientButton>
                </Grid>
                <Grid item xs={12}>
                  <GradientButton onClick={handleSaveCourse} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : (editingCourse.id ? 'Update Course' : 'Add Course')}
                  </GradientButton>
                  <Button onClick={() => {setEditingCourse(null); setSelectedCourse(null);}} color="secondary" style={{ marginLeft: '1rem' }}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}

            {editingCourse && editingCourse.id && (
              <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="white">Course Hold</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Hold Start Date"
                    value={courseHold.start_date}
                    onChange={(newValue) => setCourseHold(prev => ({ ...prev, start_date: newValue }))}
                    renderInput={(params) => <GlassInput {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Hold End Date"
                    value={courseHold.end_date}
                    onChange={(newValue) => setCourseHold(prev => ({ ...prev, end_date: newValue }))}
                    renderInput={(params) => <GlassInput {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GlassInput
                    fullWidth
                    label="Hold Reason"
                    value={courseHold.reason}
                    onChange={(e) => setCourseHold(prev => ({ ...prev, reason: e.target.value }))}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GradientButton 
                    onClick={handleApplyCourseHold} 
                    disabled={!courseHold.start_date || !courseHold.end_date || !courseHold.reason}
                  >
                    Apply Course Hold
                  </GradientButton>
                </Grid>
              </Grid>
            )}

            {error && (
              <Typography color="error" style={{ marginTop: '1rem' }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <GradientButton onClick={onClose}>Cancel</GradientButton>
            <GradientButton type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Save Student'}
            </GradientButton>
          </DialogActions>
        </form>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          <Alert 
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
            severity={snackbar.severity}
            sx={{ 
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              '& .MuiAlert-icon': {
                color: 'white'
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </GlassDialog>
    </>
  );
}

export default AddEditStudentModal;