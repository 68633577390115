import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField, 
  Alert,
  useTheme,
  useMediaQuery,
  Tab,
  Tabs,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { getFreingTrainers, getTrainerTransitionNotifications } from '../api';
import CircleIcon from '@mui/icons-material/Circle';

import { getCoursesEndingSoon, listCoordinators, sendMessage,getCourseHoldsEndingSoon, getApproachingFeeDue } from '../api';

const GlassContainer = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(17, 25, 40, 0.95)',
    backdropFilter: 'blur(16px)',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: theme.palette.common.white,
    minWidth: '300px',
  }
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: '10px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '& .MuiTableCell-root': {
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: theme.palette.common.white,
  borderRadius: '50px',
  padding: '10px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 27, 107, 0.4)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
    boxShadow: '0 6px 20px rgba(255, 27, 107, 0.6)',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.23)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).replace(/ /g, '-');
};

const CourseCompletionPage = () => {
  const [coursesEndingSoon, setCoursesEndingSoon] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coordinators, setCoordinators] = useState([]);
  const [selectedCoordinator, setSelectedCoordinator] = useState('');
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');
  const [holdsEndingSoon, setHoldsEndingSoon] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [holdDetailsOpen, setHoldDetailsOpen] = useState(false);
  const [selectedHold, setSelectedHold] = useState(null);
  const [freeingTrainers, setFreeingTrainers] = useState([]);
const [transitionNotifications, setTransitionNotifications] = useState([]);
const [feeDueStudents, setFeeDueStudents] = useState([]);



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          coursesResponse, 
          coordinatorsResponse, 
          holdsResponse,
          trainersResponse,
          transitionsResponse,
          feeDueResponse  // Add this
        ] = await Promise.all([
          getCoursesEndingSoon(),
          listCoordinators(),
          getCourseHoldsEndingSoon(),
          getFreingTrainers(),
          getTrainerTransitionNotifications(),
          getApproachingFeeDue()  // Add this
        ]);
        
        setCoursesEndingSoon(coursesResponse.data);
        setCoordinators(coordinatorsResponse);
        setHoldsEndingSoon(holdsResponse.data);
        setFreeingTrainers(trainersResponse.data);
        setTransitionNotifications(transitionsResponse.data);
        setFeeDueStudents(feeDueResponse.data.results);  // Add this
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const handleCoordinatorChange = (event) => {
    setSelectedCoordinator(event.target.value);
  };

  const handleOpenMessageModal = (student) => {
    setSelectedStudent(student);
    setMessageModalOpen(true);
  };

  const handleSendMessage = async () => {
    if (!subject.trim() || !message.trim()) {
      setError('Both subject and message are required.');
      return;
    }
    setError('');
    setSending(true);
    try {
      await sendMessage({
        recipient: selectedStudent.id,
        subject: subject,
        body: message
      });
      handleCloseMessageModal();
      // You might want to show a success notification here
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
    }
    setSending(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOpenHoldDetails = (hold) => {
    setSelectedHold(hold);
    setHoldDetailsOpen(true);
  };

  const renderTrainerTransitionsTable = () => {
    if (freeingTrainers.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" py={4}>
          <Typography color="white" variant="h6">
            No trainers are getting free in the next 5 days
          </Typography>
        </Box>
      );
    }
  
    return (
      <GlassTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Trainer</TableCell>
              <TableCell>Total Hours Freeing</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Class Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {freeingTrainers.map((trainer) => (
              trainer.ending_courses.map((course, index) => (
                <TableRow key={`${trainer.id}-${index}`}>
                  {index === 0 && (
                    <TableCell rowSpan={trainer.ending_courses.length}>
                      <Box>
                        <Typography variant="subtitle1">{trainer.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          ({trainer.email})
                        </Typography>
                      </Box>
                    </TableCell>
                  )}
                  {index === 0 && (
                    <TableCell rowSpan={trainer.ending_courses.length}>
                      {Math.round(trainer.total_freeing_hours * 100) / 100} hours
                    </TableCell>
                  )}
                  <TableCell>{course.student_name}</TableCell>
                  <TableCell>{course.course_name}</TableCell>
                  <TableCell>{formatDate(course.end_date)}</TableCell>
                  <TableCell>{course.class_time || 'Not set'}</TableCell>
                </TableRow>
              ))
            ))}
          </TableBody>
        </Table>
        <Box mt={2} px={2}>
          <Typography variant="body2" color="textSecondary">
            * Showing trainers who will be free in the next 5 days from their personal training assignments
          </Typography>
        </Box>
      </GlassTableContainer>
    );
  };

  const renderFeeDueTable = () => (
    <GlassTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Student</TableCell>
            <TableCell>Fee Due Amount</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Days Left</TableCell>
            <TableCell>Coordinator</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {feeDueStudents.map((student) => (
            <TableRow key={student.id}>
              <TableCell>
                <Box>
                  <Typography variant="subtitle2">{student.name}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {student.email}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>₹{student.fee_due}</TableCell>
              <TableCell>{formatDate(student.fee_due_date)}</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  <CircleIcon 
                    sx={{ 
                      fontSize: 12, 
                      color: student.days_left <= 2 ? 'error.main' : 
                             student.days_left <= 3 ? 'warning.main' : 
                             'success.main' 
                    }} 
                  />
                  {student.days_left} days
                </Box>
              </TableCell>
              <TableCell>
                {student.coordinator ? student.coordinator.name : 'Not Assigned'}
              </TableCell>
              <TableCell>{student.fee_method || 'Not Specified'}</TableCell>
              <TableCell>
                <GradientButton 
                  onClick={() => handleOpenMessageModal(student)}
                  size="small"
                >
                  Send Reminder
                </GradientButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </GlassTableContainer>
  );

  const renderHoldsTable = () => (
    <GlassTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Student</TableCell>
            <TableCell>Course</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Days Left</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {holdsEndingSoon.map((hold) => (
            <TableRow key={hold.id}>
              <TableCell>{hold.student.name}</TableCell>
              <TableCell>{hold.course.name}</TableCell>
              <TableCell>{formatDate(hold.end_date)}</TableCell>
              <TableCell>{hold.days_left}</TableCell>
              <TableCell>
                <Tooltip title="View Details">
                  <IconButton 
                    onClick={() => handleOpenHoldDetails(hold)}
                    sx={{ color: 'white' }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </GlassTableContainer>
  );

  const HoldDetailsDialog = () => (
    <StyledDialog
      open={holdDetailsOpen}
      onClose={() => setHoldDetailsOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Hold Details</DialogTitle>
      <DialogContent>
        {selectedHold && (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Student Information
            </Typography>
            <Typography>Name: {selectedHold.student.name}</Typography>
            <Typography>Email: {selectedHold.student.email}</Typography>
            
            <Typography variant="h6" sx={{ mt: 3 }} gutterBottom>
              Course Information
            </Typography>
            <Typography>Course: {selectedHold.course.name}</Typography>
            <Typography>Start Date: {formatDate(selectedHold.start_date)}</Typography>
            <Typography>End Date: {formatDate(selectedHold.end_date)}</Typography>
            <Typography>Days Left: {selectedHold.days_left}</Typography>
            
            <Typography variant="h6" sx={{ mt: 3 }} gutterBottom>
              Hold Details
            </Typography>
            <Typography>Reason: {selectedHold.reason}</Typography>
            <Typography>Requested By: {selectedHold.requested_by}</Typography>
            <Typography>Approved By: {selectedHold.approved_by}</Typography>
            <Typography>Created At: {new Date(selectedHold.created_at).toLocaleString()}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <GradientButton onClick={() => setHoldDetailsOpen(false)}>
          Close
        </GradientButton>
      </DialogActions>
    </StyledDialog>
  );

  const handleCloseMessageModal = () => {
    setMessageModalOpen(false);
    setSelectedStudent(null);
    setSubject('');
    setMessage('');
    setError('');
  };

  const filteredCourses = selectedCoordinator
    ? coursesEndingSoon.filter(student => student.coordinator && student.coordinator.id === selectedCoordinator)
    : coursesEndingSoon;

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      );
    }
  
    return (
      <GlassContainer sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom color="white">
          Course Management
        </Typography>
        
        <Tabs 
  value={selectedTab} 
  onChange={handleTabChange}
  sx={{
    mb: 3,
    '& .MuiTab-root': {
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: 'white'
      }
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'white'
    }
  }}
>
  <Tab label="Courses Ending Soon" />
  <Tab label="Course Holds Ending Soon" />
  <Tab 
    label={
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        Trainer Transitions
        {freeingTrainers.length > 0 && (
          <Box
            sx={{
              bgcolor: 'error.main',
              borderRadius: '50%',
              width: 8,
              height: 8,
              animation: 'pulse 2s infinite'
            }}
          />
        )}
      </Box>
    }
  />
  <Tab 
    label={
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        Fee Due
        {feeDueStudents.length > 0 && (
          <Box
            sx={{
              bgcolor: 'error.main',
              borderRadius: '50%',
              width: 8,
              height: 8,
              animation: 'pulse 2s infinite'
            }}
          />
        )}
      </Box>
    }
  />
</Tabs>
  
{(() => {
  switch (selectedTab) {
    case 0:
      return (
        <>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="coordinator-select-label" sx={{ color: 'white' }}>
              Filter by Coordinator
            </InputLabel>
            <StyledSelect
              labelId="coordinator-select-label"
              value={selectedCoordinator}
              label="Filter by Coordinator"
              onChange={handleCoordinatorChange}
            >
              <MenuItem value="">
                <em>All Coordinators</em>
              </MenuItem>
              {coordinators.map((coordinator) => (
                <MenuItem key={coordinator.id} value={coordinator.id}>
                  {coordinator.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <GlassTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student</TableCell>
                  {!isMobile && <TableCell>Coordinator</TableCell>}
                  <TableCell>Course</TableCell>
                  <TableCell>End Date</TableCell>
                  {!isMobile && <TableCell>Trainer</TableCell>}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCourses.map((student) =>
                  student.ending_courses.map((course, index) => (
                    <TableRow key={`${student.student.id}-${index}`}>
                      {index === 0 && (
                        <TableCell rowSpan={student.ending_courses.length}>
                          {student.student.full_name}
                        </TableCell>
                      )}
                      {!isMobile && index === 0 && (
                        <TableCell rowSpan={student.ending_courses.length}>
                          {student.coordinator ? student.coordinator.name : 'N/A'}
                        </TableCell>
                      )}
                      <TableCell>{course.course_name}</TableCell>
                      <TableCell>{formatDate(course.end_date)}</TableCell>
                      {!isMobile && <TableCell>{course.trainer ? course.trainer.full_name : 'N/A'}</TableCell>}
                      {index === 0 && (
                        <TableCell rowSpan={student.ending_courses.length}>
                          <GradientButton 
                            onClick={() => handleOpenMessageModal(student.student)}
                          >
                            Send Message
                          </GradientButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </GlassTableContainer>
        </>
      );
      case 1:
        return renderHoldsTable();
      case 2:
        return renderTrainerTransitionsTable();
      case 3:
        return renderFeeDueTable();
      default:
        return null;
    }
})()}

      

      <Dialog open={messageModalOpen} onClose={handleCloseMessageModal} fullWidth maxWidth="sm">
        <DialogTitle sx={{ background: 'rgba(0, 0, 0, 0.8)', color: 'white' }}>
          Send Message to {selectedStudent?.full_name}
        </DialogTitle>
        <DialogContent sx={{ background: 'rgba(0, 0, 0, 0.8)', color: 'white' }}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <StyledTextField
            autoFocus
            margin="dense"
            id="subject"
            label="Subject"
            type="text"
            fullWidth
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            sx={{ mb: 2 }}
          />
          <StyledTextField
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ background: 'rgba(0, 0, 0, 0.8)', color: 'white' }}>
          <Button onClick={handleCloseMessageModal} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <GradientButton onClick={handleSendMessage} disabled={sending}>
            {sending ? <CircularProgress size={24} /> : 'Send'}
          </GradientButton>
        </DialogActions>
      </Dialog>
      <HoldDetailsDialog />
    </GlassContainer>
  );
};

export default CourseCompletionPage;